import React from 'react';

const MainView = () => {
	return (
		<section className="mv-section">
			<img className="" src="/img/MV.png" alt="メイン画像" />
		</section>
	);
};

export default MainView;
